import TextField from "@mui/material/TextField";
import { Controller, useFormContext } from "react-hook-form";
import React from "react";
import { Autocomplete } from "@mui/material";

export const FormAutocomplete = ({
  name,
  label,
  options,
  required = true,
  dataCy,
}) => {
  const { control, getValues, setValue, clearErrors } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ fieldState }) => {
        return (
          <Autocomplete
            onChange={(_e, newValue) => {
              setValue(name, newValue?.id);
              setValue(`${name}Label`, newValue?.label);
              clearErrors(name);
            }}
            options={options}
            value={getValues(`${name}Label`) || ""}
            disablePortal
            data-cy={dataCy}
            renderInput={(params) => (
              <TextField
                {...params}
                error={!!fieldState.error}
                required={required}
                label={label}
              />
            )}
          />
        );
      }}
    />
  );
};
