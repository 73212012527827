import * as React from "react";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import Checkbox from "@mui/material/Checkbox";
import { Controller, useFormState } from "react-hook-form";
import { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import sanitizeHtml from "sanitize-html";

export const FormCheckboxComponent = ({
  name,
  control,
  options,
  label,
  errors,
  helperText,
  methods,
  currentSelectedValues,
  sxContainer,
  sxOption,
  sxOptionLabel,
}) => {
  const [selectedItems, setSelectedItems] = useState(currentSelectedValues);
  const { setValue, clearErrors, setError } = methods;

  const defaultOptions = {
    allowedTags: ["a"],
    allowedAttributes: {
      a: ["href", "target", "rel", "class"],
    },
  };

  const sanitize = (dirty, selectedOptions) => ({
    __html: sanitizeHtml(dirty, { ...defaultOptions, ...selectedOptions }),
  });

  // we are handling the selection manually here
  const handleSelect = (value) => {
    const isPresent = selectedItems.indexOf(value);
    if (isPresent !== -1) {
      const remaining = selectedItems.filter((item) => item !== value);
      setSelectedItems(remaining);
    } else {
      setSelectedItems((prevItems) => [...prevItems, value]);
    }
  };

  // we are setting form value manually here
  useEffect(() => {
    setValue(name, selectedItems, { shouldTouch: true });

    if (selectedItems.length !== 0) {
      clearErrors(name);
    } else {
      setError(name);
    }
  }, [selectedItems, clearErrors, name, setError, setValue]);

  const { isSubmitted } = useFormState({ control });

  return (
    <FormControl
      sx={sxContainer}
      required
      component="fieldset"
      variant="standard"
      error={!!(errors?.[name] && isSubmitted)}
    >
      <FormLabel component="legend" sx={{ color: "black", mb: 1.4 }}>
        {label}
      </FormLabel>
      <FormGroup>
        {options.map((option) => {
          return (
            <FormControlLabel
              sx={sxOption}
              control={
                <Controller
                  name={name}
                  control={control}
                  render={() => {
                    return (
                      <Checkbox
                        data-cy={"TermsAndConditionCheckbox"}
                        checked={selectedItems.includes(option.value)}
                        onChange={() => handleSelect(option.value)}
                        inputProps={{
                          "aria-label": option.aria,
                        }}
                      />
                    );
                  }}
                />
              }
              label={
                <Typography
                  sx={sxOptionLabel}
                  dangerouslySetInnerHTML={sanitize(option.label, options)}
                />
              }
              key={option.value}
            />
          );
        })}
      </FormGroup>
      <FormHelperText>{helperText}</FormHelperText>
    </FormControl>
  );
};
