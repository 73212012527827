import { Tooltip } from "@mui/material";
import Typography from "@mui/material/Typography";
import * as React from "react";

export const TooltipComponent = ({ text, placement, children }) => {
  return (
    <Tooltip
      title={<Typography fontSize={15}> {text}</Typography>}
      placement={placement ? placement : "top"}
      arrow
    >
      {/*div needed that tooltip is working on child component*/}
      <div>{children}</div>
    </Tooltip>
  );
};
