// Create styles
import { Font, StyleSheet } from "@react-pdf/renderer";
import arial from "../assets/fonts/Arial.ttf";
import arialBold from "../assets/fonts/Arial_Bold.ttf";

Font.register({
  family: "Arial",
  fonts: [{ src: arial }, { src: arialBold, fontWeight: "bold" }],
});

export const pdfDocumentStyles = StyleSheet.create({
  page: {
    backgroundColor: "white",
    fontFamily: "Arial",
  },
  pageContainer: {
    margin: "16mm",
    height: "243mm",
  },
  copyrightContainer: {
    padding: "15",
  },
  borderContainerTop: {
    height: "6mm",
    flexDirection: "row",
    marginTop: "6mm",
  },
  borderContainerBottom: {
    marginTop: "25",
    height: "6mm",
    flexDirection: "row",
  },
  borderBox: {
    flexGrow: 1,
    width: "50%",
  },
  borderTextRight: {
    fontSize: "6",
    textAlign: "right",
    paddingTop: "5",
    height: "6mm",
  },
  copyrightBox: {
    textAlign: "center",
    fontWeight: "400",
    fontSize: "10pt",
    color: "rgba(0,0,0,0.6)",
  },

  sectionHeader: {
    textAlign: "left",
    fontSize: "16pt",
    color: "black",
    marginBottom: "20",
  },
  sectionHeaderId: {
    textAlign: "left",
    fontSize: "14pt",
    fontWeight: "bold",
  },
  sectionContentContainerLast: {
    flexDirection: "row",
    flexWrap: "wrap",
    borderBottom: "0pt",
  },
  sectionContentBox: {
    width: "50%",
    marginBottom: "20",
  },
  sectionSchoolContentBox: {
    flexGrow: 1,
    width: "100%",
    marginBottom: "20",
  },
  emptyContentBox: {
    flexGrow: 1,
    width: "50%",
  },
  headlineBox: {
    margin: "0mm",
    height: "20mm",
    padding: "15",
  },
  headline: {
    textAlign: "right",
    fontWeight: "500",
    fontSize: "20pt",
    color: "rgba(0,0,0,0.87)",
  },
  contentValue: {
    textAlign: "left",
    fontWeight: "400",
    fontSize: "13.5pt",
    color: "rgba(0,0,0,0.87)",
  },
  contentLabel: {
    textAlign: "left",
    fontWeight: "400",
    fontSize: "10pt",
    color: "rgba(0,0,0,0.6)",
  },

  signatureBox: {
    width: "90%",
    height: "100pt",
    borderBottom: "1pt solid black",
  },
  infoTextContainer: {
    width: "100%",
    border: "1pt solid black",
    padding: "5pt 10pt",
    marginBottom: "20pt",
  },
  infoTexte: {
    fontSize: "9pt",
    marginVertical: "5pt",
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    backgroundColor: "white",
    margin: "0mm",
    height: "30mm",
  },
  logoContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: "white",
    margin: "0mm",
    height: "30mm",
  },

  logo: {
    width: "160pt",
    height: "35pt",
  },
});
