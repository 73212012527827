import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import { Radio, RadioGroup } from "@mui/material";
import { useTranslation } from "react-i18next";

export const FormInputRadio = ({
  name,
  choicesPrefix,
  label,
  choices,
  errors,
  disabled,
}) => {
  const { control } = useFormContext();
  const { t } = useTranslation();
  const formControlLabels = choices.map((choice, index) => (
    <FormControlLabel
      key={choice}
      value={choice}
      control={<Radio />}
      label={t(`pages.insertForm.${choicesPrefix}.${choice}`)}
      disabled={disabled[index]}
    />
  ));
  return (
    <FormControl
      required
      fullWidth
      error={Boolean(errors?.[name])}
      aria-label={`${label}.`}
    >
      <FormLabel id={`${name}-select-helper-label`}>{label}</FormLabel>
      <Controller
        rules={{ required: true }}
        control={control}
        name={name}
        render={({ field }) => (
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            {...field}
          >
            {formControlLabels}
          </RadioGroup>
        )}
      />
      <FormHelperText>{errors?.[name]?.message}</FormHelperText>
    </FormControl>
  );
};
