import React, { useContext, useEffect, useState } from "react";
import styled from "@emotion/styled";
import LibraryAddOutlinedIcon from "@mui/icons-material/LibraryAddOutlined";
import EditNoteOutlinedIcon from "@mui/icons-material/EditNoteOutlined";
import { SvgIcon } from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { FormInputText } from "../components/FormInputText";
import { NextButton } from "../components/formButtons";
import { generatePath, useLocation, useNavigate } from "react-router-dom";
import {
  PATH_CANCEL_TRIP,
  PATH_EDIT_TRIP,
  PATH_INFO_PAGE,
} from "../constants/routes-constants";
import { useTranslation } from "react-i18next";
import Button from "@mui/material/Button";
import axios from "axios";
import { AlertComponent } from "../components/alert";
import { TooltipComponent } from "../components/TooltipComponent";
import {
  generateAuthorizationHeader,
  removeHistoryState,
} from "../utils/utils";
import { FormDataContext } from "../contexts/FormDataContext";
import AppConfig from "../AppConfig";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 3rem;
  margin: 3rem 0;
`;
const ChooseContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 0;
  border: 1px solid lightgrey;
  border-radius: 5px;
  min-height: 100px;
  min-width: 45%;
`;

const Icon = styled(SvgIcon)`
  font-size: 5rem;
  color: #bfe0d3;
`;

const Title = styled.h2`
  color: #162a6b;
  text-align: center;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 1rem;
`;

const StButton = styled(Button)`
  width: 206px !important;
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  column-gap: 1rem;
`;

export const ChooseEntryTypePage = () => {
  const { t } = useTranslation();
  const methods = useForm({ defaultValues: { ticketRequestId: "" } });
  const navigate = useNavigate();
  const [showErrorAlert, setShowErrorAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const { updateTicketData } = useContext(FormDataContext);
  const { state } = useLocation();
  const {
    handleSubmit,
    register,
    formState: { errors },
    resetField,
  } = methods;

  useEffect(() => {
    if (state?.showSuccess) {
      setShowSuccessAlert(true);
    }
    register("ticketRequestId", {
      required: { value: true, message: t("messages.entityIdMessage") },
    });
  }, []);

  const onEdit = (data) => {
    removeHistoryState();
    axios({
      method: "get",
      url: `${AppConfig.REACT_APP_API_URL}/api/ticket-request/${data.ticketRequestId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: generateAuthorizationHeader(),
      },
    })
      .then((result) => {
        updateTicketData(result.data);
        navigate(generatePath(PATH_EDIT_TRIP, { id: data.ticketRequestId }));
      })
      .catch(() => {
        setShowErrorAlert(true);
        resetField("ticketRequestId");
      });
  };

  const onCreate = () => {
    removeHistoryState();
    navigate(PATH_INFO_PAGE);
  };

  const onDelete = (data) => {
    removeHistoryState();
    axios({
      method: "get",
      url: `${AppConfig.REACT_APP_API_URL}/api/ticket-request/${data.ticketRequestId}`,
      headers: {
        "Content-Type": "application/json",
        Authorization: generateAuthorizationHeader(),
      },
    })
      .then((result) => {
        updateTicketData(result.data);
        navigate(generatePath(PATH_CANCEL_TRIP, { id: data.ticketRequestId }));
      })
      .catch(() => {
        setShowErrorAlert(true);
        resetField("ticketRequestId");
      });
  };

  const errorAlert = (
    <AlertComponent
      type={"error"}
      message={t("pages.error.noData")}
      open={showErrorAlert}
      handleClose={() => setShowErrorAlert(false)}
    >
      sx={{ bottom: { xs: 90, sm: 0 } }}
    </AlertComponent>
  );

  const successAlert = (
    <AlertComponent
      type={"success"}
      message={t("pages.success.deleteSuccess")}
      open={showSuccessAlert}
      handleClose={() => setShowSuccessAlert(false)}
    >
      sx={{ bottom: { xs: 90, sm: 0 } }}
    </AlertComponent>
  );

  return (
    <Wrapper>
      {errorAlert}
      {successAlert}
      <ChooseContainer>
        <Icon>
          <LibraryAddOutlinedIcon fontSize={"inherit"} />
        </Icon>
        <Title>{t("pages.chooseEntryType.newEntry")}</Title>
        <StButton
          variant="contained"
          onClick={onCreate}
          sx={{ mb: { xs: 3, sm: 0 }, width: { xs: "100%", sm: "auto" } }}
          data-cy={"create-button"}
        >
          {t("button.create")}
        </StButton>
      </ChooseContainer>
      <ChooseContainer>
        <Icon>
          <EditNoteOutlinedIcon fontSize={"inherit"} />
        </Icon>
        <Title>{t("pages.chooseEntryType.editEntry")}</Title>
        <FormProvider {...methods}>
          <form>
            <Col>
              <TooltipComponent text={t("messages.idHelperMessage")}>
                <FormInputText
                  name={"ticketRequestId"}
                  label={"ID des Begleitbogens"}
                  ariaLabel={"TicketRequestId"}
                  errors={errors || ""}
                />
              </TooltipComponent>
              <ButtonWrapper>
                <NextButton
                  title={t("button.edit")}
                  handleSubmit={handleSubmit}
                  onSubmit={onEdit}
                />
                <Button
                  variant="outlined"
                  onClick={handleSubmit(onDelete)}
                  type={"submit"}
                  sx={{
                    mb: { xs: 3, sm: 0 },
                    width: { xs: "100%", sm: "auto" },
                  }}
                  data-cy={"cancel-button"}
                >
                  {t("button.cancel")}
                </Button>
              </ButtonWrapper>
            </Col>
          </form>
        </FormProvider>
      </ChooseContainer>
    </Wrapper>
  );
};
