import { Alert, IconButton, Snackbar } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

export const AlertComponent = ({ type, message, open, handleClose }) => {
  const action = (
    <IconButton size="small" color="inherit" onClick={handleClose}>
      <CloseIcon fontSize="small" />
    </IconButton>
  );

  return (
    <Snackbar
      anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      open={open}
      action={action}
      data-cy={"alert"}
    >
      <Alert action={action} variant="filled" severity={type}>
        {message}
      </Alert>
    </Snackbar>
  );
};
