import * as React from "react";
import { useContext, useEffect, useRef, useState } from "react";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import Grid from "@mui/material/Grid";
import { FormDataContext } from "../contexts/FormDataContext";
import {
  DATE_FORMAT_GERMAN,
  DATE_FORMAT_LOCAL_DATE_TIME,
  DELETE_CONFIRMATION,
  FORM_LABELS,
  REVIEW_CHECKBOX,
  TICKET_TYPE,
} from "../constants/constants";
import { FormButtons } from "../components/formButtons";
import { Alert, Divider } from "@mui/material";
import axios from "axios";
import { FormCheckboxComponent } from "../components/FormCheckboxComponent";
import { StepContext } from "../contexts/StepContext";
import Box from "@mui/material/Box";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  PATH_CHOOSE_TYPE,
  PATH_SEND_SUCCESSFULLY,
} from "../constants/routes-constants";
import { WidgetInstance } from "friendly-challenge";
import { PDFDataContext } from "../contexts/PDFDataContext";
import { Trans, useTranslation } from "react-i18next";
import moment from "moment/moment";

import generatePdfDocument from "../components/generatePdfDocument";
import { generateAuthorizationHeader } from "../utils/utils";
import AppConfig from "../AppConfig";

export default function Review() {
  const { t } = useTranslation();
  const { id } = useParams();
  // Create Refs for friendly captcha
  const container = useRef();
  const widget = useRef();
  const isCaptchaDisabled = AppConfig.REACT_APP_FC_DISABLED === "1";

  const location = useLocation();
  const { completedSteps, updateCompletedSteps, setCompletedSteps } =
    useContext(StepContext);
  const navigate = useNavigate();

  const { ticketData, updateTicketData } = useContext(FormDataContext);

  const { updatePdfData1 } = useContext(PDFDataContext);

  const [hasError, setHasError] = useState(false);
  const [sendIsDisabled, setSendDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const school = JSON.parse(localStorage.getItem("schoolData"));

  const methods = useForm({
    defaultValues: {
      termsAndConditions: [],
      frCaptcha: "",
    },
  });

  const {
    register,
    handleSubmit,
    control,
    setError,
    clearErrors,
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = methods;

  const termsAndConditions = watch("termsAndConditions");
  const frCaptcha = watch("frCaptcha");

  useEffect(() => {
    if (!id) {
      return;
    }
    updateTicketData({
      ...ticketData,
      schoolLabel: school.label,
    });
  }, []);

  useEffect(() => {
    if (!widget.current && container.current) {
      widget.current = new WidgetInstance(container.current, {
        startMode: "auto",
        doneCallback: doneCallback,
        errorCallback: errorCallback,
      });
    }

    return () => {
      if (widget.current !== undefined) {
        widget.current.destroy();
      }
    };
  }, [container]);

  useEffect(() => {
    if (!completedSteps.includes(location.pathname)) {
      updateCompletedSteps(location.pathname);
    }

    register("termsAndConditions", {
      required: true,
    });

    register("frCaptcha", {
      required: !isCaptchaDisabled,
    });

    setError("frCaptcha", {
      type: "custom",
    });
  }, []);

  useEffect(() => {
    if (termsAndConditions.length !== 1) {
      setSendDisabled(true);
      setError("termsAndConditions", {
        type: "custom",
      });
    } else if (frCaptcha !== "" || isCaptchaDisabled) {
      setSendDisabled(false);
    } else {
      setSendDisabled(true);
    }
  }, [termsAndConditions, frCaptcha]);

  function clearFormData() {
    updateTicketData([]);
    setCompletedSteps([]);
  }

  function getFormData() {
    return Object.assign({}, ticketData);
  }

  const doneCallback = (solution) => {
    // Captcha was solved. The form can be submitted.

    clearErrors("frCaptcha");
    setValue("frCaptcha", solution);
  };

  const errorCallback = (err) => {
    // There was an error when trying to solve the Captcha.

    setError("frCaptcha", {
      type: "custom",
      message: err.error.message,
    });
  };

  function removeEventListenerOnReload() {
    window.removeEventListener("load", (_event) => {
      /* empty function */
    });
  }

  function onSubmit() {
    const frCaptchaSolution = getValues("frCaptcha");
    if (id) {
      axios({
        method: "delete",
        url: `${AppConfig.REACT_APP_API_URL}/api/ticket-request/${id}`,
        headers: { Authorization: generateAuthorizationHeader() },
        data: { frCaptchaSolution: frCaptchaSolution },
      })
        .then(() => {
          updateTicketData([]);
          navigate(PATH_CHOOSE_TYPE, { state: { showSuccess: true } });
        })
        .catch(() => {
          setHasError(true);
        });
    } else {
      const formData = getFormData();
      formData.dateOfTrip = moment(
        formData.dateOfTrip,
        DATE_FORMAT_GERMAN
      ).format(DATE_FORMAT_LOCAL_DATE_TIME);

      setSendDisabled(true);
      setLoading(true);
      const method = formData.id ? "put" : "post";
      const url = formData.id
        ? `${AppConfig.REACT_APP_API_URL}/api/ticket-request/${formData.id}`
        : `${AppConfig.REACT_APP_API_URL}/api/ticket-request`;
      // Continue with valid captcha solution
      axios({
        method: method,
        url: url,
        headers: {
          "Content-Type": "application/json",
          Authorization: generateAuthorizationHeader(),
        },
        data: { ...formData, frCaptchaSolution },
      })
        .then((result) => {
          const pdfData = { ...formData, entityId: result.data };
          updatePdfData1(pdfData);
          generatePdfDocument(pdfData);
          navigate(PATH_SEND_SUCCESSFULLY);
          clearFormData();
          removeEventListenerOnReload();
        })
        .catch(() => {
          window.scrollTo(0, 0);
          setHasError(true);
          setSendDisabled(false);
          setLoading(false);
        });
    }
  }

  const handleBack = () => {
    if (id) {
      updateTicketData([]);
    }
    navigate(-1);
  };

  const isDepartureLocationSchoolLocation = () => {
    return ticketData.schoolAsStart === "yes";
  };

  const isDestinationLocationSchoolLocation = () => {
    return ticketData.schoolAsEnd === "yes";
  };

  const Title = ({ title }) => {
    return (
      <Typography align="left" variant="h6" gutterBottom>
        {title}
      </Typography>
    );
  };

  const EntryBlock = ({ title, children }) => {
    return (
      <React.Fragment>
        <Title title={title} />
        <Grid container spacing={1} data-cy="review-container">
          {children}
        </Grid>
        <Divider variant="middle" sx={{ mt: 1, mb: 2, mx: 0 }} />
      </React.Fragment>
    );
  };

  const errorMessage = (
    <Alert
      severity="error"
      sx={{
        mb: 2,
      }}
    >
      {t("pages.reviewForm.errorText")}
    </Alert>
  );

  const infoMessage = (
    <Alert
      severity="info"
      sx={{
        mb: 2,
      }}
    >
      {t("pages.reviewForm.infoText")}
    </Alert>
  );

  const readTermsAndConditions = (
    <FormCheckboxComponent
      name={"termsAndConditions"}
      control={control}
      options={id ? DELETE_CONFIRMATION : REVIEW_CHECKBOX}
      label={t("pages.reviewForm.checkboxLabel")}
      errors={errors || ""}
      methods={methods}
      currentSelectedValues={[]}
      sxContainer={{ mt: 1 }}
      sxOption={{ mb: { xs: 2, sm: 0 } }}
      sxOptionLabel={{ fontSize: "1rem", color: "text.secondary" }}
    />
  );

  const captcha = (
    <div
      ref={container}
      className="frc-captcha"
      data-sitekey={`${AppConfig.REACT_APP_FC_SITE_KEY}`}
      data-lang="de"
      data-puzzle-endpoint={`${AppConfig.REACT_APP_FC_ENDPOINT}`}
    />
  );

  return (
    <React.Fragment>
      {hasError ? errorMessage : infoMessage}
      <EntryBlock title={t("pages.reviewForm.reviewHeader")}>
        <Grid key={"schoolLabel"} item xs={12} sm={12}>
          <ListItemText
            primary={ticketData.schoolLabel}
            secondary={t(FORM_LABELS["schoolLabel"])}
            secondaryTypographyProps={{
              "aria-label": `${FORM_LABELS["schoolLabel"]}.`,
            }}
          />
        </Grid>
        <Grid key={"className"} item xs={12} sm={6}>
          <ListItemText
            primary={ticketData.className}
            secondary={t(FORM_LABELS["className"])}
            secondaryTypographyProps={{
              "aria-label": `${FORM_LABELS["className"]}.`,
            }}
          />
        </Grid>
        <Grid key={"dateOfTrip"} item xs={12} sm={6}>
          <ListItemText
            primary={moment(ticketData.dateOfTrip).format(DATE_FORMAT_GERMAN)}
            secondary={t(FORM_LABELS["dateOfTrip"])}
            secondaryTypographyProps={{
              "aria-label": `${FORM_LABELS["dateOfTrip"]}.`,
            }}
          />
        </Grid>
        <Grid key={"numberOfTravelers"} item xs={12} sm={6}>
          <ListItemText
            primary={ticketData.numberOfTravelers}
            secondary={t(FORM_LABELS["numberOfTravelers"])}
            secondaryTypographyProps={{
              "aria-label": `${FORM_LABELS["numberOfTravelers"]}.`,
            }}
          />
        </Grid>
        <Grid key={"numberOfTravelersWithoutOwnTicket"} item xs={12} sm={6}>
          <ListItemText
            primary={ticketData.numberOfTravelersWithoutOwnTicket}
            secondary={
              <Trans
                i18nKey={"pages.insertForm.numberOfTravelersWithoutOwnTicket"}
                components={{ underline: <u /> }}
              />
            }
            secondaryTypographyProps={{
              "aria-label": `${FORM_LABELS["numberOfTravelersWithoutOwnTicket"]}.`,
            }}
          />
        </Grid>
        <Grid key={"type"} item xs={12} sm={12}>
          <ListItemText
            primary={t(
              ticketData.type in TICKET_TYPE
                ? TICKET_TYPE[ticketData.type]
                : ticketData.type
            )}
            secondary={t(FORM_LABELS["type"])}
            secondaryTypographyProps={{
              "aria-label": `${FORM_LABELS["type"]}.`,
            }}
          />
        </Grid>
        <Grid key={"schoolAsStart"} item xs={12} sm={6}>
          <ListItemText
            primary={
              isDepartureLocationSchoolLocation()
                ? t("pages.reviewForm.schoolAsStart")
                : ticketData.postCodeStartingPoint +
                  " " +
                  ticketData.departureLocation
            }
            secondary={t(FORM_LABELS["schoolAsStart"])}
            secondaryTypographyProps={{
              "aria-label": `${FORM_LABELS["schoolAsStart"]}.`,
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6}></Grid>
        <Grid key={"schoolAsEnd"} item xs={12} sm={6}>
          <ListItemText
            primary={
              isDestinationLocationSchoolLocation()
                ? t("pages.reviewForm.schoolAsEnd")
                : ticketData.postCodeGoal + " " + ticketData.destinationLocation
            }
            secondary={t(FORM_LABELS["schoolAsEnd"])}
            secondaryTypographyProps={{
              "aria-label": `${FORM_LABELS["schoolAsEnd"]}.`,
            }}
          />
        </Grid>
      </EntryBlock>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          {readTermsAndConditions}
          <Box sx={{ mb: 2, mt: 1 }}>
            {!isCaptchaDisabled ? captcha : <></>}
          </Box>
          <FormButtons
            handleBack={handleBack}
            handleSubmit={handleSubmit}
            onSubmit={onSubmit}
            isDisabled={sendIsDisabled}
            loading={loading}
            nextButtonLabel={"Stornieren"}
          />
        </form>
      </Box>
    </React.Fragment>
  );
}
