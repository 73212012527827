import axios from "axios";

export const getJwtTokenFromLocalStorage = () => {
  return localStorage.getItem("token");
};

export const saveJwtTokenInLocalStorage = (token) => {
  localStorage.setItem("token", token);
};

export const removeJwtTokenFromLocalStorage = () => {
  localStorage.removeItem("token");
};

export const generateAuthorizationHeader = () => {
  return "Bearer " + getJwtTokenFromLocalStorage();
};

export const fetchCityToPLZ = (
  plzData,
  registerNameForCity,
  setValueFunction
) => {
  if (plzData.length === 5) {
    axios({
      method: "get",
      url: `https://openplzapi.org/de/Localities?postalCode=${plzData}`,
      headers: { accept: "text/plain" },
    })
      .then((result) => {
        setValueFunction(registerNameForCity, result.data[0].name);
      })
      .catch(() => {});
  } else {
    setValueFunction(registerNameForCity, "");
  }
};

export const removeHistoryState = () => {
  window.history.replaceState({}, document.title);
};
