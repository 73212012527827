import * as React from "react";
import { useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import AppBar from "@mui/material/AppBar";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import Paper from "@mui/material/Paper";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import { ThemeProvider } from "@mui/material/styles";
import { FormDataProvider } from "../contexts/FormDataContext";
import theme from "../theme";
import { Divider } from "@mui/material";
import Box from "@mui/material/Box";
import Logo from "../assets/images/logo.svg";
import Verbundlogo from "../assets/images/img.png";
import { Outlet, useLocation } from "react-router-dom";
import { PATH_CHOOSE_TYPE, PATH_LOGIN } from "../constants/routes-constants";
import { PDFDataProvider } from "../contexts/PDFDataContext";
import { useTranslation } from "react-i18next";
import { StepperComponent } from "../components/Stepper";
import datenschutz from "../assets/media/Datenschutz.pdf";

export const PageContainer = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();

  useEffect(() => {
    window.addEventListener("load", (event) => {
      event.preventDefault();
    });
  }, []);

  const isLoginPage = pathname === PATH_LOGIN;
  const isChooseEntryTypePage = pathname === PATH_CHOOSE_TYPE;

  const showStepper = !isLoginPage && !isChooseEntryTypePage;

  const verticalDivider = (
    <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
  );

  const legalRights = (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        mb: 2,
        color: "text.secondary",
        fontSize: "0.875rem",
      }}
    >
      <Link
        color="inherit"
        href={datenschutz}
        rel="noopener noreferrer"
        target="_blank"
      >
        {t("footer.datenschutz")}
      </Link>
      {verticalDivider}
      <Link
        color="inherit"
        href="https://www.rmv.de/c/de/rechtliche-hinweise/impressum"
        rel="noopener noreferrer"
        target="_blank"
      >
        {t("footer.impressum")}
      </Link>
      {verticalDivider}
      <Link
        color="inherit"
        href="https://www.schuelerticket.hessen.de/wp-content/uploads/2023/09/230915_FAQ_Schulausflugsticket.pdf"
        rel="noopener noreferrer"
        target="_blank"
      >
        {t("footer.faq")}
      </Link>
    </Box>
  );

  const copyright = (
    <Typography variant="body2" color="text.secondary" align="center">
      {t("footer.title")}
    </Typography>
  );

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          boxShadow: "0px 120px 120px 120px rgba(22,42,107,0.1)",
          width: "100%",
          height: 0,
        }}
      />
      <CssBaseline />
      <AppBar
        position="absolute"
        color="default"
        elevation={0}
        sx={{
          position: "relative",
        }}
      >
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <Link
            sx={{ display: "flex", alignItems: "flex-end" }}
            underline={"none"}
            display={"inline-flex"}
            href={PATH_CHOOSE_TYPE}
          >
            <Box
              component={"img"}
              src={Logo}
              alt="RMV Logo"
              sx={{
                height: {
                  xs: 20,
                  md: 30,
                  lg: 40,
                },
              }}
            />
            <Typography
              variant="h6"
              color="inherit"
              sx={{
                color: "#162a6b",
                fontSize: {
                  xs: "1rem",
                  md: "1.5rem",
                  lg: "2rem",
                },
                lineHeight: {
                  xs: "1rem",
                  md: "1.5rem",
                  lg: "2rem",
                },
                textTransform: "uppercase",
                fontWeight: 700,
                ml: "1rem",
                pr: "1rem",
                fontStyle: "italic",
              }}
            >
              {t("title")}
            </Typography>
          </Link>
          <Box
            component={"img"}
            src={Verbundlogo}
            alt="Logo Verkehrsverbünde"
            sx={{
              height: {
                xs: 40,
                md: 50,
                lg: 60,
              },
            }}
          />
        </Toolbar>
      </AppBar>
      <Container
        component="main"
        maxWidth={isLoginPage ? "sm" : "md"}
        sx={{ mb: 4 }}
      >
        <Paper
          variant="outlined"
          sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}
        >
          {showStepper ? <StepperComponent /> : null}
          <FormDataProvider>
            <PDFDataProvider>
              <Outlet />
            </PDFDataProvider>
          </FormDataProvider>
        </Paper>
        {legalRights}
        {copyright}
      </Container>
    </ThemeProvider>
  );
};
