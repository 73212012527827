import { Alert } from "@mui/material";
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { PATH_REGISTER_TRIP } from "../constants/routes-constants";
import { useLocation, useNavigate } from "react-router-dom";
import { FormCheckboxComponent } from "../components/FormCheckboxComponent";
import { TERMS_AND_CONDITIONS } from "../constants/constants";
import Box from "@mui/material/Box";
import { FormButtons } from "../components/formButtons";
import { useForm } from "react-hook-form";
import { FormDataContext } from "../contexts/FormDataContext";
import { StepContext } from "../contexts/StepContext";

export const InfoPage = () => {
  const { t } = useTranslation();
  const { updateInfoData, infoData } = useContext(FormDataContext);
  const navigate = useNavigate();
  const { completedSteps, updateCompletedSteps } = useContext(StepContext);
  const location = useLocation();
  const [nextDisabled, setNextDisabled] = useState(true);

  const methods = useForm({
    defaultValues: {
      termsAndConditions: [],
    },
  });

  const {
    handleSubmit,
    control,
    formState: { errors },
    watch,
    setError,
  } = methods;

  const termsAndConditions = watch("termsAndConditions");

  useEffect(() => {
    if (termsAndConditions.length !== 1) {
      setNextDisabled(true);
      setError("termsAndConditions", {
        type: "custom",
      });
    } else {
      setNextDisabled(false);
    }
  }, [termsAndConditions]);

  const infoMessage1 = (
    <Alert severity="info" sx={{ mb: 2, pb: 0 }}>
      {t("pages.infoPage.text1")}
    </Alert>
  );
  const infoMessage2 = (
    <Alert severity="info" sx={{ mb: 2, pb: 0 }}>
      {t("pages.infoPage.text2")}
    </Alert>
  );

  const infoMessage3 = (
    <Alert severity="info" sx={{ mb: 2, pb: 0 }}>
      {t("pages.infoPage.text3")}
    </Alert>
  );
  const infoMessage4 = (
    <Alert severity="warning" sx={{ mb: 2, pb: 0 }}>
      <Trans i18nKey={"pages.infoPage.text4"} components={{ 1: <br /> }} />
    </Alert>
  );

  const readTermsAndConditions = (
    <FormCheckboxComponent
      name={"termsAndConditions"}
      control={control}
      options={TERMS_AND_CONDITIONS}
      label={t("pages.reviewForm.checkboxLabel")}
      errors={errors || ""}
      methods={methods}
      currentSelectedValues={
        infoData?.termsAndConditions?.[0] === "policy"
          ? infoData.termsAndConditions
          : []
      }
      sxContainer={{ mt: 1 }}
      sxOption={{ mb: { xs: 2, sm: 0 } }}
      sxOptionLabel={{ fontSize: "1rem", color: "text.secondary" }}
    />
  );

  const onSubmit = (data) => {
    updateInfoData(data);
    if (!completedSteps.includes(location.pathname)) {
      updateCompletedSteps(location.pathname);
    }
    navigate(PATH_REGISTER_TRIP);
  };

  return (
    <React.Fragment>
      {infoMessage1}
      {infoMessage2}
      {infoMessage3}
      {infoMessage4}
      <form onSubmit={handleSubmit(onSubmit)}>
        {readTermsAndConditions}
        <Box sx={{ mb: 2, mt: 1 }} />
        <FormButtons
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          isDisabled={nextDisabled}
          handleBack={() => navigate(-1)}
        />
      </form>
    </React.Fragment>
  );
};
