import TextField from "@mui/material/TextField";
import { Controller, useFormContext } from "react-hook-form";
import React from "react";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";

export const FormDatePicker = ({ name, label, errors, required = true }) => {
  const { control } = useFormContext();
  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="de">
          <DatePicker
            {...field}
            minDate={moment()}
            slotProps={{
              textField: {
                fullWidth: true,
                required: required,
                error: errors?.[name],
              },
            }}
            name={name}
            control={control}
            label={label}
            renderInput={(params) => (
              <TextField error={errors?.[name]} {...params} />
            )}
          />
        </LocalizationProvider>
      )}
    />
  );
};
