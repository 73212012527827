import React from "react";
import { saveAs } from "file-saver";
import { pdf } from "@react-pdf/renderer";
import PDFDocument from "./PDFDocument";
import moment from "moment";
import { DATE_FORMAT_GERMAN } from "../constants/constants";

const generatePdfDocument = async (data) => {
  const blob = await pdf(<PDFDocument dataForm1={data} />).toBlob();
  const fileNameDate = moment(data.dateOfTrip).format(DATE_FORMAT_GERMAN);
  saveAs(
    blob,
    `ID${data.entityId}_Begleitbogen_zum_Schulausflugticket_${fileNameDate}.pdf`
  );
};

export default generatePdfDocument;
