import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { PATH_LOGIN } from "../constants/routes-constants";
import axios from "axios";
import {
  generateAuthorizationHeader,
  getJwtTokenFromLocalStorage,
  removeJwtTokenFromLocalStorage,
} from "../utils/utils";
import AppConfig from "../AppConfig";

const LoggedInRoute = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [loggedIn, setLoggedIn] = useState(false);
  const [jwtToken, setJwtToken] = useState(getJwtTokenFromLocalStorage());

  useEffect(() => {
    setJwtToken(getJwtTokenFromLocalStorage());
    if (jwtToken === undefined) {
      setLoggedIn(false);
      navigate(PATH_LOGIN);
    } else {
      validateToken();
    }
  }, [location]);

  const validateToken = () => {
    axios({
      method: "get",
      url: `${AppConfig.REACT_APP_API_URL}/api/auth/validate`,
      headers: { Authorization: generateAuthorizationHeader() },
    })
      .then(() => {
        setLoggedIn(true);
      })
      .catch(() => {
        setLoggedIn(false);
        removeJwtTokenFromLocalStorage();
        navigate(PATH_LOGIN);
      });
  };

  return loggedIn ? <>{children}</> : null;
};

export default LoggedInRoute;
