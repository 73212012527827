export const CHIP_CARD_PATTERN = /^[0-9.]{6,15}$/;

// used for firstName, lastName, street and city
// exclude the following characters
export const NAME_PATTERN = /^[^<>_:;+*~'#!?"ยง&/()[\]\\{}=%$@0-9]*$/;

export const BANK_NAME_PATTERN = /^[^<>_:;+*~'#!?"ยง&/()[\]\\{}=%$@]*$/;

// only german addresses
export const STREET_NUMBER_PATTERN =
  /^[0-9]{1,4}\s?[a-zA-Z]?(\s?-\s?[0-9]{1,4}?[a-zA-Z]?)?$/;

// only german addresses
export const POSTAL_CODE_PATTERN = /^[0-9]{5}$/;

export const EMAIL_PATTERN = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
export const COUPON_PATTERN = /^[0-9a-zA-Z]{18}$/;
export const DATES_PATTERN =
  /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/;

// only SEPA area
export const IBAN_PATTERN =
  /^[A-Za-z]{2}[0-9]{2}\s?([0-9A-Z]{4}\s?){2,6}([0-9A-Z]{1,3})?$/;

export const BIC_PATTERN = /^[A-Z0-9]{4}[A-Z]{2}[A-Z0-9]{2}([A-Z0-9]{3})?$/;
