import TextField from "@mui/material/TextField";
import { Controller, useFormContext } from "react-hook-form";
import React from "react";
import { InputAdornment } from "@mui/material";

export const FormInputText = ({
  name,
  label,
  errors,
  required = true,
  isDisabled = false,
  show = true,
  ariaLabel,
  prefix,
  type,
  fullWidth = true,
}) => {
  const { control } = useFormContext();
  return show ? (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <TextField
          InputProps={{
            "aria-label": ariaLabel ? ariaLabel : `${label}.`,
            startAdornment: prefix ? (
              <InputAdornment position="start">{prefix}</InputAdornment>
            ) : null,
          }}
          type={type ? type : "text"}
          aria-label={ariaLabel ? ariaLabel : `${label}.`}
          disabled={isDisabled}
          required={required}
          label={label}
          error={Boolean(errors?.[name])}
          helperText={errors?.[name]?.message}
          fullWidth={fullWidth}
          variant="outlined"
          {...field}
        />
      )}
    />
  ) : null;
};
