import React, { useEffect, useState } from "react";
import { NextButton } from "../components/formButtons";
import { useTranslation } from "react-i18next";
import { FormInputText } from "../components/FormInputText";
import { FormProvider, useForm } from "react-hook-form";
import styled from "@emotion/styled";
import { PATH_CHOOSE_TYPE } from "../constants/routes-constants";
import { useNavigate } from "react-router-dom";
import { FormAutocomplete } from "../components/FormAutocomplete";
import axios from "axios";
import { saveJwtTokenInLocalStorage } from "../utils/utils";
import { AlertComponent } from "../components/alert";
import AppConfig from "../AppConfig";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0;
`;

const Col = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3rem;
  margin: 1rem 0;
`;

export const LogIn = () => {
  const { t } = useTranslation();
  const methods = useForm({ defaultValues: { login: "" } });
  const navigate = useNavigate();

  const [schools, setSchools] = useState([]);
  const [showErrorAlert, setShowErrorAlert] = useState(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
    resetField,
  } = methods;

  useEffect(() => {
    // Continue with valid captcha solution
    axios({
      method: "get",
      url: `${AppConfig.REACT_APP_API_URL}/api/schools`,
      headers: { "Content-Type": "application/json" },
    })
      .then((result) => {
        setSchools(
          result.data.map((school) => ({
            ...school,
            label: `${school.name}, ${school.district}, ${school.postalCode}, ${school.city}, ${school.streetAndHousenumber}`,
          }))
        );
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    register("school", {
      required: {
        value: true,
      },
    });
    register("login", {
      required: { value: true, message: t("messages.passwordMessage") },
    });
  }, []);

  const login = (loginData) => {
    axios
      .post(`${AppConfig.REACT_APP_API_URL}/api/auth`, loginData)
      .then((res) => {
        saveJwtTokenInLocalStorage(res.data.jwtToken);
        navigate(PATH_CHOOSE_TYPE);
      })
      .catch(() => {
        setShowErrorAlert(true);
        resetField("login");
      });
  };

  const onSubmit = (data) => {
    const schoolData = schools.filter((school) => {
      return school.id === data.school;
    });
    localStorage.setItem("schoolData", JSON.stringify(schoolData.pop()));
    const loginData = {
      schoolId: data.school.toString(),
      password: data.login,
    };
    login(loginData);
  };

  const errorAlert = (
    <AlertComponent
      type={"error"}
      message={t("pages.error.loginError")}
      open={showErrorAlert}
      handleClose={() => {
        setShowErrorAlert(false);
      }}
    >
      sx={{ bottom: { xs: 90, sm: 0 } }}
    </AlertComponent>
  );

  return (
    <Wrapper>
      {errorAlert}
      <h1>{t("pages.login.title")}</h1>
      <FormProvider {...methods}>
        <form>
          <Col>
            <FormAutocomplete
              name={"school"}
              options={schools}
              label={t("pages.insertForm.school")}
              dataCy={"login"}
            />
          </Col>
          <Col>
            <FormInputText
              name={"login"}
              label={"Passwort"}
              ariaLabel={"Passwort"}
              type={"password"}
              fullWidth={false}
              errors={errors || ""}
            />
            <NextButton
              title={t("button.login")}
              handleSubmit={handleSubmit}
              onSubmit={onSubmit}
            />
          </Col>
        </form>
      </FormProvider>
    </Wrapper>
  );
};
