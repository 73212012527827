export const DATE_FORMAT_GERMAN = "DD.MM.YYYY";
export const DATE_FORMAT_LOCAL_DATE_TIME = "YYYY-MM-DDTHH:mm:ss";

export const TERMS_AND_CONDITIONS = [
  {
    value: "policy",
    label: `Hiermit bestätige ich, dass ich die oben genannten Infos gelesen und zur Kenntnis genommen habe.`,
    aria: "Hiermit bestätige ich, dass ich die Datenschutzerklärung gelesen und zur Kenntnis genommen habe.",
  },
];

export const REVIEW_CHECKBOX = [
  {
    value: "policy",
    label: `Hiermit bestätige ich, dass ich die oben genannten Daten richtig und vollständig eingeben habe.`,
    aria: "Hiermit bestätige ich, dass ich die oben genannten Daten richtig und vollständig eingeben habe.",
  },
];

export const DELETE_CONFIRMATION = [
  {
    value: "policy",
    label: `Hiermit bestätige ich, dass ich den oben genannten Schulausflug stornieren möchte.`,
    aria: "Hiermit bestätige ich, dass ich den oben genannten Schulausflug stornieren möchte.",
  },
];

export const SCHOOL_AS_START = [
  {
    value: "true",
    label: `Ja, Startort = Schulort`,
    aria: "Ja",
  },
  {
    value: "false",
    label: `Nein, Startort nicht = Schulort`,
    aria: "Nein",
  },
];

export const EXCLUDED_FIELDS = [
  "school",
  "dateOfTrip",
  "schoolLabel",
  "schoolAsStart",
  "schoolAsEnd",
  "entityId",
];
export const EXCLUDED_FIELDS_FOR_PDF = [
  "school",
  "dateOfTrip",
  "schoolLabel",
  "numberOfTravelersWithoutOwnTicket",
  "schoolAsStart",
  "schoolAsEnd",
  "entityId",
];

export const FORM_LABELS = {
  schoolAsStart: "pages.insertForm.schoolAsStart.label",
  schoolAsEnd: "pages.insertForm.schoolAsEnd.label",
  type: "pages.insertForm.ticketType.label",
  className: "pages.insertForm.className",
  schoolLabel: "pages.insertForm.schoolLabel",
  numberOfTravelers: "pages.insertForm.numberOfTravelers",
  numberOfTravelersWithoutOwnTicket:
    "pages.insertForm.numberOfTravelersWithoutOwnTicket",
  postCodeStartingPoint: "pages.insertForm.postCodeStartingPoint",
  departureLocation: "pages.insertForm.departureLocation",
  postCodeGoal: "pages.insertForm.postCodeGoal",
  destinationLocation: "pages.insertForm.destinationLocation",
  dateOfTrip: "pages.insertForm.dateOfTrip",
  startLocation: "pages.insertForm.startLocation",
  endLocation: "pages.insertForm.endLocation",
};

export const FORM_VALUE_PREFIX = {
  schoolAsStart: "pages.insertForm.schoolAsStart",
  schoolAsEnd: "pages.insertForm.schoolAsEnd",
  type: "pages.insertForm.ticketType",
};

export const REQUIRED_MESSAGE = {
  value: true,
  message: "Dies ist ein Pflichtfeld.",
};

export const TICKET_TYPE = {
  DAY: "pages.insertForm.ticketType.DAY",
  SINGLE_TRIP: "pages.insertForm.ticketType.SINGLE_TRIP",
};
