import React from "react";
import { Document, Font, Image, Page, Text, View } from "@react-pdf/renderer";
import font from "../assets/fonts/Arial.ttf";
import logo from "../assets/images/img.png";
import {
  DATE_FORMAT_GERMAN,
  FORM_LABELS,
  TICKET_TYPE,
} from "../constants/constants";
import moment from "moment";
import { pdfDocumentStyles } from "../styles/PDFDocumentStyles";
import { useTranslation } from "react-i18next";

// Register font
Font.register({
  family: "Arial",
  src: font,
});

// Create Document Component
const PDFDocument = ({ dataForm1 }) => {
  const { t } = useTranslation();

  const EntryBlock = ({ title, children }) => {
    return (
      <View>
        <Text style={pdfDocumentStyles.sectionHeader}>
          {title}
          <Text style={pdfDocumentStyles.sectionHeaderId}>
            {` (ID: ${dataForm1.entityId})`}
          </Text>
        </Text>
        <View style={pdfDocumentStyles.sectionContentContainerLast}>
          {children}
        </View>
      </View>
    );
  };

  return (
    <Document>
      <Page size="A4" style={pdfDocumentStyles.page}>
        <View style={pdfDocumentStyles.pageContainer}>
          <View style={pdfDocumentStyles.headerContainer}>
            <Text style={pdfDocumentStyles.headline}>
              {t("pages.pdf.title")}
            </Text>
            <Image style={pdfDocumentStyles.logo} src={logo} />
          </View>
          <View style={pdfDocumentStyles.infoTextContainer}>
            <Text style={pdfDocumentStyles.infoTexte}>
              {t("pages.infoPage.text1")}
            </Text>
            <Text style={pdfDocumentStyles.infoTexte}>
              {t("pages.infoPage.text2")}
            </Text>
            <Text style={pdfDocumentStyles.infoTexte}>
              {t("pages.infoPage.text3")}
            </Text>
          </View>
          <EntryBlock title={t("pages.pdf.angaben")}>
            <View
              style={pdfDocumentStyles.sectionSchoolContentBox}
              key={"schoolLabel"}
            >
              <Text style={pdfDocumentStyles.contentLabel}>
                {t(FORM_LABELS["schoolLabel"])}
              </Text>
              <Text style={pdfDocumentStyles.contentValue}>
                {dataForm1.schoolLabel}
              </Text>
            </View>
            <View style={pdfDocumentStyles.sectionContentBox} key={"className"}>
              <Text style={pdfDocumentStyles.contentLabel}>
                {t(FORM_LABELS["className"])}
              </Text>
              <Text style={pdfDocumentStyles.contentValue}>
                {dataForm1.className}
              </Text>
            </View>
            <View
              style={pdfDocumentStyles.sectionContentBox}
              key={"dateOfTrip"}
            >
              <Text style={pdfDocumentStyles.contentLabel}>
                {t(FORM_LABELS["dateOfTrip"])}
              </Text>
              <Text style={pdfDocumentStyles.contentValue}>
                {moment(dataForm1.dateOfTrip).format(DATE_FORMAT_GERMAN)}
              </Text>
            </View>
            <View
              style={pdfDocumentStyles.sectionContentBox}
              key={"numberOfTravelers"}
            >
              <Text style={pdfDocumentStyles.contentLabel}>
                {t(FORM_LABELS["numberOfTravelers"])}
              </Text>
              <Text style={pdfDocumentStyles.contentValue}>
                {dataForm1.numberOfTravelers}
              </Text>
            </View>
            <View
              style={pdfDocumentStyles.sectionContentBox}
              key={"numberOfTravelersWithoutOwnTicket"}
            >
              <Text style={pdfDocumentStyles.contentLabel}>
                {t("pages.pdf.noTicketPart1")}
                <Text
                  style={[
                    pdfDocumentStyles.contentLabel,
                    {
                      textDecoration: "underline",
                      textDecorationColor: "black",
                    },
                  ]}
                >
                  {t("pages.pdf.noTicketPart2")}
                </Text>
                {t("pages.pdf.noTicketPart3")}
              </Text>

              <Text style={pdfDocumentStyles.contentValue}>
                {dataForm1.numberOfTravelersWithoutOwnTicket}
              </Text>
            </View>
            <View style={pdfDocumentStyles.sectionContentBox} key={"type"}>
              <Text style={pdfDocumentStyles.contentLabel}>
                {t(FORM_LABELS["type"])}
              </Text>
              <Text style={pdfDocumentStyles.contentValue}>
                {t(
                  dataForm1.type in TICKET_TYPE
                    ? TICKET_TYPE[dataForm1.type]
                    : dataForm1.type
                )}
              </Text>
            </View>
            <View style={pdfDocumentStyles.emptyContentBox}>
              <Text style={pdfDocumentStyles.contentLabel}></Text>
              <Text style={pdfDocumentStyles.contentValue}></Text>
            </View>
            <View
              style={pdfDocumentStyles.sectionContentBox}
              key={"schoolAsStart"}
            >
              <Text style={pdfDocumentStyles.contentLabel}>
                {t(FORM_LABELS["schoolAsStart"])}
              </Text>
              <Text style={pdfDocumentStyles.contentValue}>
                {dataForm1.schoolAsStart === "yes"
                  ? t("pages.reviewForm.schoolAsStart")
                  : dataForm1.postCodeStartingPoint +
                    " " +
                    dataForm1.departureLocation}
              </Text>
            </View>
            <View style={pdfDocumentStyles.emptyContentBox}>
              <Text style={pdfDocumentStyles.contentLabel}></Text>
              <Text style={pdfDocumentStyles.contentValue}></Text>
            </View>
            <View
              style={pdfDocumentStyles.sectionContentBox}
              key={"schoolAsStart"}
            >
              <Text style={pdfDocumentStyles.contentLabel}>
                {t(FORM_LABELS["schoolAsEnd"])}
              </Text>
              <Text style={pdfDocumentStyles.contentValue}>
                {dataForm1.schoolAsEnd === "yes"
                  ? t("pages.reviewForm.schoolAsEnd")
                  : dataForm1.postCodeGoal +
                    " " +
                    dataForm1.destinationLocation}
              </Text>
            </View>
            <View style={pdfDocumentStyles.emptyContentBox}>
              <Text style={pdfDocumentStyles.contentLabel}></Text>
              <Text style={pdfDocumentStyles.contentValue}></Text>
            </View>
            <View style={pdfDocumentStyles.sectionContentBox}>
              <View style={pdfDocumentStyles.signatureBox}></View>
              <Text style={pdfDocumentStyles.contentLabel}>
                {t("pages.pdf.timeDate")}
              </Text>
            </View>

            <View style={pdfDocumentStyles.sectionContentBox}>
              <View style={pdfDocumentStyles.signatureBox}></View>
              <Text style={pdfDocumentStyles.contentLabel}>
                {t("pages.pdf.stempel")}
              </Text>
            </View>
          </EntryBlock>
        </View>
      </Page>
    </Document>
  );
};

export default PDFDocument;
