import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    background: {
      default: "#f1f2f6",
    },
    primary: {
      main: "#162a6b",
    },
  },
  typography: {
    fontFamily: ["Arial", "sans-serif"].join(","),
  },
});

theme.components = {
  MuiToolbar: {
    styleOverrides: {
      root: {
        minHeight: "50px",
        backgroundColor: "white",
        paddingLeft: "12px",
        paddingRight: "12px",
        [theme.breakpoints.up("sm")]: {
          minHeight: "60px",
        },
        [theme.breakpoints.up("md")]: {
          minHeight: "70px",
        },
        [theme.breakpoints.up("lg")]: {
          minHeight: "100px",
        },
      },
    },
  },
};

export default theme;
