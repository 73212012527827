import React, { useContext } from "react";
import Step from "@mui/material/Step";
import { StepButton, Stepper, useMediaQuery } from "@mui/material";
import {
  PATH_CANCEL_TRIP,
  PATH_EDIT_TRIP,
  PATH_INFO_PAGE,
  PATH_REGISTER_TRIP,
  PATH_REVIEW_DATA,
} from "../constants/routes-constants";
import { StepContext } from "../contexts/StepContext";
import {
  generatePath,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import theme from "../theme";

export const StepperComponent = () => {
  const { completedSteps } = useContext(StepContext);

  const navigate = useNavigate();
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const { id } = useParams();

  const isMinMd = useMediaQuery(theme.breakpoints.up("md"));

  const stepperProps = {
    orientation: isMinMd ? "horizontal" : "vertical",
  };

  const stepEntries = [
    { label: t("pages.infoPage.title"), link: PATH_INFO_PAGE },
    {
      label: t("pages.insertForm.title"),
      link: id ? generatePath(PATH_EDIT_TRIP, { id }) : PATH_REGISTER_TRIP,
    },
    {
      label: t("pages.reviewForm.reviewLabel"),
      link: id ? generatePath(PATH_CANCEL_TRIP, { id }) : PATH_REVIEW_DATA,
    },
  ];

  const indexOfCurrentStep = stepEntries.findIndex(
    (entry) => entry.link === pathname
  );

  function getStepperContent() {
    return stepEntries.map((entry, index) => {
      const isLastEntry = stepEntries.length === index + 1;
      const ariaLabelStepper = isLastEntry ? `${entry.label}.` : entry.label;

      if (completedSteps.includes(entry.link) && id === undefined) {
        return (
          <Step key={entry.label} onClick={() => navigate(entry.link)}>
            <StepButton aria-label={ariaLabelStepper}>{entry.label}</StepButton>
          </Step>
        );
      } else {
        return (
          <Step key={entry.label}>
            <StepButton aria-label={ariaLabelStepper}>{entry.label}</StepButton>
          </Step>
        );
      }
    });
  }

  return (
    <Stepper
      {...stepperProps}
      sx={{
        pt: 3,
        pb: 5,
      }}
      activeStep={indexOfCurrentStep}
    >
      {getStepperContent()}
    </Stepper>
  );
};
