import React from "react";
import { useNavigate } from "react-router-dom";
import Button from "@mui/material/Button";
import { PATH_REGISTER_TRIP } from "../constants/routes-constants";
import Grid from "@mui/material/Grid";

export const CookieDenyError = () => {
  const navigate = useNavigate();

  function handleOnClick() {
    navigate(PATH_REGISTER_TRIP);
  }

  return (
    <React.Fragment>
      <div>
        Schade, dass Sie der Nutzung von Cookies nicht zustimmen. Zur Nutzung
        des Erstattungsportals müssen Sie dennoch den Cookies zustimmen.
      </div>
      <Grid item xs={12} style={{ textAlign: "right" }}>
        <Button
          variant="contained"
          sx={{ mt: 5, width: { xs: "100%", sm: "auto" } }}
          onClick={handleOnClick}
        >
          Zurück zum Erstattungsportal
        </Button>
      </Grid>
    </React.Fragment>
  );
};
