import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

export const PDFDataContext = createContext([]);

export function PDFDataProvider(props) {
  const [pdfData1, setPdfData1] = useState([]);

  const updatePdfData1 = (data) => setPdfData1(data);

  return (
    <PDFDataContext.Provider
      value={{
        pdfData1,
        updatePdfData1,
      }}
    >
      {props.children}
    </PDFDataContext.Provider>
  );
}

PDFDataProvider.propTypes = {
  children: PropTypes.any,
};
