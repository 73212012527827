import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

export const FormDataContext = createContext([]);

export function FormDataProvider(props) {
  const [ticketData, setTicketData] = useState([]);
  const [infoData, setInfoData] = useState([]);

  const updateTicketData = (data) => setTicketData(data);
  const updateInfoData = (data) => setInfoData(data);

  return (
    <FormDataContext.Provider
      value={{
        infoData,
        updateInfoData,
        ticketData,
        updateTicketData,
      }}
    >
      {props.children}
    </FormDataContext.Provider>
  );
}

FormDataProvider.propTypes = {
  children: PropTypes.any,
};
