export const BASE_PATH = "http//:localhost:8000";
export const PATH_LOGIN = "/login";
export const PATH_CHOOSE_TYPE = "/choose-type";
export const PATH_COOKIE_ERROR = "/cookie-error";
export const PATH_REGISTER_TRIP = "/register-trip";
export const PATH_EDIT_TRIP = "/edit-trip/:id";
export const PATH_INFO_PAGE = "/info";
export const PATH_REVIEW_DATA = "/review-data";
export const PATH_CANCEL_TRIP = "/cancel-trip/:id";
export const PATH_SEND_SUCCESSFULLY = "/request-refund-success";
