import Button from "@mui/material/Button";
import * as React from "react";
import Grid from "@mui/material/Grid";
import { useMediaQuery } from "@mui/material";
import theme from "../theme";
import SendIcon from "@mui/icons-material/Send";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  PATH_CHOOSE_TYPE,
  PATH_REVIEW_DATA,
} from "../constants/routes-constants";
import { useLocation } from "react-router-dom";

const BackButton = ({ handleBack }) => {
  return (
    <Button
      onClick={handleBack}
      fullWidth
      variant={"outlined"}
      sx={{
        mb: { xs: 3, sm: 0 },
        width: { xs: "100%", sm: "auto" },
        mr: { sm: 2 },
      }}
      data-cy={"back-button"}
    >
      Zurück
    </Button>
  );
};

export const NextButton = ({
  handleSubmit,
  onSubmit,
  title,
  isDisabled = false,
}) => {
  return (
    <Button
      data-cy={"nextButton"}
      variant="contained"
      type="submit"
      disabled={isDisabled}
      onClick={handleSubmit(onSubmit)}
      sx={{ mb: { xs: 3, sm: 0 }, width: { xs: "100%", sm: "auto" } }}
    >
      {title}
    </Button>
  );
};

const SaveButton = ({
  handleSubmit,
  onSubmit,
  title,
  isDisabled = false,
  loading = false,
}) => {
  return (
    <LoadingButton
      data-cy={"saveButton"}
      variant="contained"
      type="submit"
      disabled={isDisabled}
      onClick={handleSubmit(onSubmit)}
      loading={loading}
      loadingPosition="start"
      startIcon={<SendIcon />}
      sx={{ mb: { xs: 3, sm: 0 }, width: { xs: "100%", sm: "auto" } }}
    >
      {title}
    </LoadingButton>
  );
};

export const FormButtons = ({
  handleBack,
  handleSubmit,
  onSubmit,
  isDisabled,
  loading,
  nextButtonLabel,
}) => {
  const location = useLocation();
  const showSaveButton = location.pathname === PATH_REVIEW_DATA;
  const showBackButton = location.pathname !== PATH_CHOOSE_TYPE;
  const isMinSm = useMediaQuery(theme.breakpoints.up("sm"));

  const backButton = (
    <>{showBackButton && <BackButton handleBack={handleBack} />}</>
  );

  const nextButton = (
    <>
      {showSaveButton ? (
        <SaveButton
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          title={"Absenden"}
          isDisabled={isDisabled}
          loading={loading}
        />
      ) : (
        <NextButton
          handleSubmit={handleSubmit}
          onSubmit={onSubmit}
          title={nextButtonLabel ? nextButtonLabel : "Weiter"}
          isDisabled={isDisabled}
        />
      )}
    </>
  );

  return (
    <>
      <Grid
        item
        xs={12}
        sx={{ mb: { xs: 2, sm: 0 } }}
        style={{ color: "rgba(0, 0, 0, 0.6)", fontSize: "0.75rem" }}
      >
        * Pflichtfelder
      </Grid>

      <Grid item xs={12} style={{ textAlign: "right" }}>
        {isMinSm ? (
          <>
            {backButton}
            {nextButton}
          </>
        ) : (
          <>
            {nextButton}
            {backButton}
          </>
        )}
      </Grid>
    </>
  );
};
