import * as React from "react";
import { useContext } from "react";
import Typography from "@mui/material/Typography";
import { Alert, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { PATH_CHOOSE_TYPE } from "../constants/routes-constants";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import { FormDataContext } from "../contexts/FormDataContext";
import { StepContext } from "../contexts/StepContext";
import { useTranslation } from "react-i18next";
import { PDFDataContext } from "../contexts/PDFDataContext";
import PDFDocument from "../components/PDFDocument";
import { PDFDownloadLink } from "@react-pdf/renderer";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment/moment";
import { DATE_FORMAT_GERMAN } from "../constants/constants";

export const SuccessPage = () => {
  const { t } = useTranslation();
  const { updateTicketData } = useContext(FormDataContext);
  const { pdfData1 } = useContext(PDFDataContext);

  const { setCompletedSteps } = useContext(StepContext);

  const navigate = useNavigate();
  const fileNameDate = moment(pdfData1.dateOfTrip).format(DATE_FORMAT_GERMAN);

  function clearFormData() {
    updateTicketData([]);
    setCompletedSteps([]);
  }

  function handleOnClick() {
    clearFormData();
    navigate(PATH_CHOOSE_TYPE);
  }

  const displayLoading = () => {
    return <CircularProgress color="primary" size={"1rem"} />;
  };

  const DownloadButton = () => {
    return (
      <Button
        variant="contained"
        endIcon={<DownloadIcon />}
        sx={{ textTransform: "uppercase" }}
        data-cy={"download-button"}
      >
        {t("pages.pdf.title")}
      </Button>
    );
  };

  return (
    <React.Fragment>
      <Alert
        severity="success"
        sx={{
          mb: 2,
          "& .MuiAlert-icon": {
            paddingTop: "12px",
          },
        }}
      >
        <Typography variant="h5" sx={{ mb: 3 }}>
          {t("pages.success.infoText")}
        </Typography>
        <br />
        <Typography>
          <PDFDownloadLink
            document={<PDFDocument dataForm1={pdfData1} />}
            fileName={`ID${pdfData1.entityId}_Begleitbogen_zum_Schulausflugticket_${fileNameDate}.pdf`}
            style={{ textDecoration: "none" }}
          >
            {({ loading }) => (loading ? displayLoading() : DownloadButton())}
          </PDFDownloadLink>
        </Typography>
        <br />
        <Typography variant="subtitle1">
          {t("pages.success.greetings")}
        </Typography>

        <Typography variant="subtitle1">{t("pages.success.rmv")}</Typography>
      </Alert>
      <Grid item xs={12} style={{ textAlign: "right" }}>
        <Button
          variant="outlined"
          sx={{
            mt: { xs: 3, sm: 0 },
            width: { xs: "100%", sm: "auto" },
            textTransform: "uppercase",
          }}
          onClick={handleOnClick}
          data-cy={"add-new-request-button"}
        >
          {t("pages.success.again")}
        </Button>
      </Grid>
    </React.Fragment>
  );
};
