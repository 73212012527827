const findFirstExistingProperty = (properties) => {
    const notEmptyProperties = properties.filter(property => property !== undefined);
    if (notEmptyProperties.length === 0) {
        return undefined;
    }
    return notEmptyProperties[0];
}

const AppConfig = {
    REACT_APP_API_URL: findFirstExistingProperty([window.env.REACT_APP_API_URL, process.env.REACT_APP_API_URL]),
    REACT_APP_FC_SITE_KEY: findFirstExistingProperty([window.env.REACT_APP_FC_SITE_KEY, process.env.REACT_APP_FC_SITE_KEY]),
    REACT_APP_FC_ENDPOINT: findFirstExistingProperty([window.env.REACT_APP_FC_ENDPOINT, process.env.REACT_APP_FC_ENDPOINT]),
    REACT_APP_FC_DISABLED: findFirstExistingProperty([window.env.REACT_APP_FC_DISABLED, process.env.REACT_APP_FC_DISABLED]),
};

export default AppConfig;