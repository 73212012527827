import * as React from "react";
import { useContext, useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import { FormProvider, useForm } from "react-hook-form";
import { FormDataContext } from "../contexts/FormDataContext";
import { FormButtons } from "../components/formButtons";
import { FormInputText } from "../components/FormInputText";
import "moment/locale/de";
import { StepContext } from "../contexts/StepContext";
import { PATH_REVIEW_DATA } from "../constants/routes-constants";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { FormInputRadio } from "../components/FormInputRadio";
import { FormAutocomplete } from "../components/FormAutocomplete";
import { FormDatePicker } from "../components/FormDatePicker";
import moment from "moment";
import {
  DATES_PATTERN,
  POSTAL_CODE_PATTERN,
} from "../constants/regex-patterns";
import { TooltipComponent } from "../components/TooltipComponent";
import { AlertComponent } from "../components/alert";
import { fetchCityToPLZ } from "../utils/utils";

export default function TicketRequestForm() {
  const { t } = useTranslation();
  const { id } = useParams();
  const { updateTicketData, ticketData } = useContext(FormDataContext);
  const { completedSteps, updateCompletedSteps } = useContext(StepContext);
  const [showStartLocation, setShowStartLocation] = useState(true);
  const [showEndLocation, setShowEndLocation] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const [selectedSchool] = useState(
    JSON.parse(localStorage.getItem("schoolData"))
  );

  const navigate = useNavigate();
  const location = useLocation();

  const methods = useForm({
    defaultValues: {
      schoolAsStart: "yes",
      schoolAsEnd: "no",
      type: "SINGLE_TRIP",
      className: "",
      dateOfTrip: id ? moment(ticketData.dateOfTrip) : moment(),
      numberOfTravelers: "",
      numberOfTravelersWithoutOwnTicket: "",
      postCodeGoal: "",
      destinationLocation: "",
      postCodeStartingPoint: "",
      departureLocation: "",
    },
  });

  const {
    handleSubmit,
    formState: { errors },
    register,
    setValue,
    watch,
    setError,
    resetField,
    getValues,
  } = methods;

  const schoolIsStart = watch("schoolAsStart");
  const schoolIsEnd = watch("schoolAsEnd");
  const postCodeGoal = watch("postCodeGoal");
  const postCodeStartingPoint = watch("postCodeStartingPoint");

  useEffect(() => {
    if (schoolIsStart === "no") {
      setShowStartLocation(true);
    } else {
      resetField("departureLocation");
      resetField("postCodeStartingPoint");
      setShowStartLocation(false);
    }
  }, [schoolIsStart]);

  useEffect(() => {
    if (schoolIsEnd === "no") {
      setShowEndLocation(true);
    } else {
      resetField("destinationLocation");
      resetField("postCodeGoal");
      setShowEndLocation(false);
    }
  }, [schoolIsEnd]);

  //fetch corresponding city to PLZ for destination
  useEffect(() => {
    fetchCityToPLZ(postCodeGoal, "destinationLocation", setValue);
  }, [postCodeGoal]);

  //fetch corresponding city to PLZ for departure
  useEffect(() => {
    fetchCityToPLZ(postCodeStartingPoint, "departureLocation", setValue);
  }, [postCodeStartingPoint]);

  useEffect(() => {
    if (!completedSteps.includes(location.pathname)) {
      updateCompletedSteps(location.pathname);
    }

    setValue("school", selectedSchool.id);
    setValue(`schoolLabel`, selectedSchool.label);
    register("schoolLabel");
    register("school", {
      required: {
        value: true,
      },
    });
    register("className", {
      required: {
        value: true,
      },
    });
    register("dateOfTrip", {
      required: {
        value: true,
      },
      pattern: {
        value: DATES_PATTERN,
        message: t("messages.dateMessage"),
      },
    });
    register("numberOfTravelers", {
      max: { value: 60, message: t("messages.amountOfPassengersMessage") },
      min: { value: 1, message: t("messages.minAmountOfPassengersMessage") },
      required: true,
    });
    register("numberOfTravelersWithoutOwnTicket", {
      required: {
        value: true,
      },
      min: { value: 1, message: t("messages.minAmountOfPassengersMessage") },
      validate: {
        lessOrEqualAmountOfTravelers: (value) =>
          parseInt(value) <= getValues("numberOfTravelers") ||
          t("messages.amountOfPassengersWithoutTicketIsGreaterMessage"),
      },
    });
    register("type", {
      required: {
        value: true,
      },
    });
    register("schoolAsStart", {
      required: {
        value: true,
      },
    });
    register("schoolAsEnd", {
      required: {
        value: true,
      },
    });
    register("postCodeStartingPoint", {
      required: {
        value: showStartLocation,
      },
      pattern: {
        value: POSTAL_CODE_PATTERN,
        message: t("messages.plzMessage"),
      },
    });
    register("departureLocation", {
      required: {
        value: showStartLocation,
      },
    });
    register("postCodeGoal", {
      required: {
        value: showEndLocation,
      },
      pattern: {
        value: POSTAL_CODE_PATTERN,
        message: t("messages.plzMessage"),
      },
    });
    register("destinationLocation", {
      required: {
        value: showEndLocation,
      },
    });

    if (
      ticketData &&
      typeof ticketData === "object" &&
      ticketData.constructor === Object
    ) {
      checkValueOfSchoolRadioButtons();
      for (const [key, value] of Object.entries(ticketData)) {
        if (key === "dateOfTrip") {
          setValue(key, moment(value));
        } else {
          setValue(key, value);
        }
      }
    }
  }, []);

  const checkValueOfSchoolRadioButtons = () => {
    if (ticketData.postCodeStartingPoint === selectedSchool.postalCode) {
      setValue("schoolAsStart", "yes");
    } else {
      setValue("schoolAsStart", "no");
    }
    if (ticketData.postCodeGoal === selectedSchool.postalCode) {
      setValue("schoolAsEnd", "yes");
    } else {
      setValue("schoolAsEnd", "no");
    }
  };

  const alert = (
    <AlertComponent
      type={"error"}
      message={t("pages.error.noData")}
      open={showAlert}
      handleClose={() => setShowAlert(false)}
    >
      sx={{ bottom: { xs: 90, sm: 0 } }}
    </AlertComponent>
  );

  const onSubmit = (data) => {
    if (schoolIsStart === "yes" && schoolIsEnd === "yes") {
      setError("schoolAsStart", {
        type: "custom",
        message: t("messages.locationMessage"),
      });
      setError("schoolAsEnd", {
        type: "custom",
        message: t("messages.locationMessage"),
      });
    } else {
      if (schoolIsStart === "yes") {
        data = {
          ...data,
          postCodeStartingPoint: selectedSchool.postalCode,
          departureLocation: selectedSchool.city,
        };
      } else if (schoolIsEnd === "yes") {
        data = {
          ...data,
          postCodeGoal: selectedSchool.postalCode,
          destinationLocation: selectedSchool.city,
        };
      }
      data = { ...data, id: id };
      updateTicketData(data);
      navigate(PATH_REVIEW_DATA);
    }
  };

  return (
    <React.Fragment>
      {alert}
      <FormProvider {...methods}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs>
              <Grid item mb={3}>
                <FormAutocomplete
                  name={"school"}
                  options={[selectedSchool]}
                  label={t("pages.insertForm.school")}
                />
              </Grid>
              <Grid container spacing={3}>
                <Grid item mb={4} xs>
                  <FormInputText
                    name={"className"}
                    label={t("pages.insertForm.className")}
                    errors={errors || ""}
                    type={"text"}
                  />
                </Grid>
                <Grid item mb={3} xs>
                  <TooltipComponent text={t("messages.dateTooltip")}>
                    <FormDatePicker
                      name={"dateOfTrip"}
                      label={t("pages.insertForm.dateOfTrip")}
                    />
                  </TooltipComponent>
                </Grid>
              </Grid>
              <Grid container spacing={3}>
                <Grid item mb={3} xs>
                  <TooltipComponent
                    text={t("messages.numberOfPassengersTooltip")}
                  >
                    <FormInputText
                      name={"numberOfTravelers"}
                      label={t("pages.insertForm.numberOfTravelers")}
                      errors={errors || ""}
                      type={"tel"}
                    />
                  </TooltipComponent>
                </Grid>
                <Grid item mb={3} xs>
                  <TooltipComponent
                    text={t("messages.numberOfPassengersWithoutTicketTooltip")}
                  >
                    <FormInputText
                      name={"numberOfTravelersWithoutOwnTicket"}
                      label={
                        <Trans
                          i18nKey={
                            "pages.insertForm.numberOfTravelersWithoutOwnTicket"
                          }
                          components={{ underline: <u /> }}
                        />
                      }
                      errors={errors || ""}
                      type={"tel"}
                    />
                  </TooltipComponent>
                </Grid>
              </Grid>
              <Grid item mb={3}>
                <TooltipComponent
                  text={t("messages.ticketTypeTooltip")}
                  placement="top-start"
                >
                  <FormInputRadio
                    disabled={[false, false]}
                    name={"type"}
                    label={t("pages.insertForm.ticketType.label")}
                    choicesPrefix={"ticketType"}
                    choices={["SINGLE_TRIP", "DAY"]}
                    errors={errors || ""}
                  />
                </TooltipComponent>
              </Grid>
              <Grid item mb={3}>
                <FormInputRadio
                  disabled={[schoolIsEnd === "yes", false]}
                  name={"schoolAsStart"}
                  label={t("pages.insertForm.schoolAsStart.label")}
                  choicesPrefix={"schoolAsStart"}
                  choices={["yes", "no"]}
                  errors={errors || ""}
                />
              </Grid>
              <Grid container spacing={3}>
                <Grid item mb={3} xs>
                  <FormInputText
                    show={showStartLocation}
                    name={"postCodeStartingPoint"}
                    label={t("pages.insertForm.postCodeStartingPoint")}
                    errors={errors || ""}
                  />
                </Grid>
                <Grid item mb={3} xs={8}>
                  <FormInputText
                    name={"departureLocation"}
                    show={showStartLocation}
                    label={t("pages.insertForm.departureLocation")}
                    errors={errors || ""}
                    type={"text"}
                  />
                </Grid>
              </Grid>
              <Grid item mb={3}>
                <FormInputRadio
                  disabled={[schoolIsStart === "yes", false]}
                  name={"schoolAsEnd"}
                  label={t("pages.insertForm.schoolAsEnd.label")}
                  choicesPrefix={"schoolAsEnd"}
                  choices={["yes", "no"]}
                  errors={errors || ""}
                />
              </Grid>
              <Grid container spacing={3}>
                <Grid item mb={3} xs>
                  <TooltipComponent text={t("messages.destinationTooltip")}>
                    <FormInputText
                      name={"postCodeGoal"}
                      show={showEndLocation}
                      label={t("pages.insertForm.postCodeGoal")}
                      errors={errors || ""}
                    />
                  </TooltipComponent>
                </Grid>
                <Grid item mb={3} xs={8}>
                  <TooltipComponent text={t("messages.destinationTooltip")}>
                    <FormInputText
                      name={"destinationLocation"}
                      show={showEndLocation}
                      label={t("pages.insertForm.destinationLocation")}
                      errors={errors || ""}
                      type={"text"}
                    />
                  </TooltipComponent>
                </Grid>
              </Grid>
            </Grid>
            <Grid item container>
              <FormButtons
                handleBack={() => navigate(-1)}
                handleSubmit={handleSubmit}
                onSubmit={onSubmit}
              />
            </Grid>
          </Grid>
        </form>
      </FormProvider>
    </React.Fragment>
  );
}
