import { StepProvider } from "./contexts/StepContext";
import { PageContainer } from "./template/PageContainer";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import React from "react";
import {
  PATH_CANCEL_TRIP,
  PATH_CHOOSE_TYPE,
  PATH_COOKIE_ERROR,
  PATH_EDIT_TRIP,
  PATH_INFO_PAGE,
  PATH_LOGIN,
  PATH_REGISTER_TRIP,
  PATH_REVIEW_DATA,
  PATH_SEND_SUCCESSFULLY,
} from "./constants/routes-constants";
import { LogIn } from "./template/LogIn";
import LoggedInRoute from "./components/LoggedInRoute";
import { ChooseEntryTypePage } from "./template/ChooseEntryTypePage";
import { CookieDenyError } from "./template/CookieDenyError";
import TicketRequestForm from "./template/TicketRequestForm";
import { InfoPage } from "./template/InfoPage";
import Review from "./template/Review";
import { SuccessPage } from "./template/SuccessPage";

function App() {
  return (
    <div className="App">
      <StepProvider>
        <BrowserRouter>
          <Routes>
            <Route path="*" element={<Navigate to={PATH_CHOOSE_TYPE} />} />
            <Route element={<PageContainer />}>
              <Route exact path={PATH_LOGIN} element={<LogIn />} />
              <Route
                exact
                path={PATH_CHOOSE_TYPE}
                element={
                  <LoggedInRoute>
                    <ChooseEntryTypePage />
                  </LoggedInRoute>
                }
              />
              <Route
                exact
                path={PATH_COOKIE_ERROR}
                element={<CookieDenyError />}
              />
              <Route
                exact
                path={PATH_REGISTER_TRIP}
                element={
                  <LoggedInRoute>
                    <TicketRequestForm />
                  </LoggedInRoute>
                }
              />
              <Route
                exact
                path={PATH_EDIT_TRIP}
                element={
                  <LoggedInRoute>
                    <TicketRequestForm />
                  </LoggedInRoute>
                }
              />
              <Route
                exact
                path={PATH_INFO_PAGE}
                element={
                  <LoggedInRoute>
                    <InfoPage />
                  </LoggedInRoute>
                }
              />
              <Route
                exact
                path={PATH_REVIEW_DATA}
                element={
                  <LoggedInRoute>
                    <Review />
                  </LoggedInRoute>
                }
              />
              <Route
                exact
                path={PATH_CANCEL_TRIP}
                element={
                  <LoggedInRoute>
                    <Review />
                  </LoggedInRoute>
                }
              />
              <Route
                exact
                path={PATH_SEND_SUCCESSFULLY}
                element={
                  <LoggedInRoute>
                    <SuccessPage />
                  </LoggedInRoute>
                }
              />
            </Route>
          </Routes>
        </BrowserRouter>
      </StepProvider>
    </div>
  );
}

export default App;
