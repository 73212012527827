import React, { createContext, useState } from "react";
import PropTypes from "prop-types";

export const StepContext = createContext([]);

export function StepProvider(props) {
  const [completedSteps, setCompletedSteps] = useState([]);

  const updateCompletedSteps = (step) => {
    setCompletedSteps((currentCompletedSteps) => [
      ...currentCompletedSteps,
      step,
    ]);
  };

  return (
    <StepContext.Provider
      value={{
        completedSteps,
        updateCompletedSteps,
        setCompletedSteps,
      }}
    >
      {props.children}
    </StepContext.Provider>
  );
}

StepProvider.propTypes = {
  children: PropTypes.node,
};
